import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import thumbnail from "../img/seo/Perroquet-3D-01.jpeg"

function Seo({
  description,
  lang,
  meta,
  image: metaImage,
  title,
  pathname,
  keywords,
  imageHeight,
  imageWidth,
  imageStatic,
  imageProjet
}) {

  const { site } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
    `
  )

  const metaDescription = description || site.siteMetadata.description;

  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  return (
    <Helmet htmlAttributes={{ lang }} title={title} titleTemplate={`%s`}
      link={canonical ? [{ rel: "canonical", href: canonical },] : []}
      meta={[
      ]
        .concat(meta)} >
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />

      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content={"website"} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />

      <meta name="thumbnail" content={thumbnail} />
      {imageProjet &&
        <meta name="og:image" content={imageProjet} />
      }
      {!imageProjet &&
        <meta name="og:image" content={image ? image : imageStatic} />
      }

    </Helmet>
  )
}

Seo.defaultProps = { lang: `fr`, meta: [], description: ``, }

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default Seo