import React, { Component, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl, navigate } from "gatsby-plugin-intl"

import * as portfolioStyles from "./portfolio.module.scss"

import HeaderNav from "../components/Header/HeaderNav"
import Seo from "../components/seo-old"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import ScrollToTopBtn from "../components/Footer/ScrollToTopBtn"
import FooterInfos from "../components/Footer/Footer"

import { GatsbyImage } from "gatsby-plugin-image"

const ProjetPage = ({ location, intl }) => {
  var langue = intl.formatMessage({ id: "lang" })

  const data = useStaticQuery(graphql`
  query {
    allContentfulProjects(sort: { fields: year, order: DESC }) {
        edges {
          node {
            node_locale
            title
            slug
            year
            category
            sector
            location
            slogan
            mainPicture {
              title
              file {
                url
              }
              gatsbyImageData(
                width: 1200
                placeholder: BLURRED
                formats: [WEBP]
                quality:50
              )
            }
          }
        }
      }
    }
  `)

  function checkProps(location) {
    // Sélectionner l'ancre par défaut
    let stateo = { anchor: "#start" }
    if ((location.state = null)) {
      location.state = stateo
    }
    return location
  }

  checkProps(location)

  //RECUPERATION DE FILTRE VIA URL
  //on doit rajouter à chaque nouvelle catégorie
  if (location.hash) {
    var filtre = ""
    var sector = ""

    if (location.hash.substr(1) === "identiteVisuelle") {
      filtre = "Identité visuelle"
    } else if (location.hash.substr(1) === "applicationMobile") {
      filtre = "Application mobile"
    } else if (location.hash.substr(1) === "siteWeb") {
      filtre = "Site web"
    } else if (location.hash.substr(1) === "illustration") {
      filtre = "Illustration"
    } else if (location.hash.substr(1) === "3D") {
      filtre = "3D"
    } else if (location.hash.substr(1) === "animation") {
      filtre = "Animation"
    } else if (location.hash.substr(1) === "environement") {
      sector = "Environement"
    } else if (location.hash.substr(1) === "sante") {
      sector = "Santé"
    } else if (location.hash.substr(1) === "culture") {
      sector = "Culture"
    } else if (location.hash.substr(1) === "immobilier") {
      sector = "Immobilier"
    } else if (location.hash.substr(1) === "juridique") {
      sector = "Juridique"
    } else if (location.hash.substr(1) === "evenementiel") {
      sector = "Evénementiel"
    } else if (location.hash.substr(1) === "restauration") {
      sector = "Restauration"
    }
    else if (location.hash.substr(1) === "technologie") {
      sector = "Technologie"
    }
  }

  return (
    <div id="start">
      <Seo
        lang={intl.formatMessage({ id: "lang" })}
        pathname={location.pathname}
        title={intl.formatMessage({ id: "titreMetadataPortfolio" })}
        description={intl.formatMessage({ id: "descriptionMetadataPortfolio" })}
        keywords={intl.formatMessage({ id: "keywordsMetadataPortfolio" })}
        imageStatic={"https://perroquet.eu/static/40917a87246748aead77d75b1144fcf2/47498/Perroquet-3D-01.jpg"}
        imageWidth={"1200"}
        imageHeight={"791"}
      />

      <HeaderNav />
      <ProjetComponent
        data={data}
        location={location}
        filtre={filtre}
        sector={sector}
        langue={langue}
        intl={intl}
      />
    </div>
  )
}

export default injectIntl(ProjetPage)



const ProjetComponent = (props) => {

  const [data, setData] = useState([]);
  const [condition, setCondition] = useState("All");
  const [conditionSector, setConditionSector] = useState("All");
  const [filtre, setFiltre] = useState("none");
  const [mentionLegales, setMentionLegales] = useState(false);
  const [btnCat, setBtnCat] = useState("Catégories");
  const [btnSec, setBtnSec] = useState("Secteur");
  const [showCat, setShowCat] = useState(false);
  const [showSec, setShowSec] = useState(false);
  const [isBtn, setIsBtn] = useState("true");
  const [intl, setIntl] = useState(undefined);
  const [langue, setLangue] = useState(undefined);




  const handleChange = event => {
    setLangue(event.target.value)

  }
  useEffect(() => {
    setIntl(props.intl)
    setLangue(props.langue)
    if (!props.filtre) {
      if (props.sector) {
        setConditionSector(props.sector)
        setBtnSec(props.sector)
        return props.filtre
      }
    } else {
      setCondition(props.filtre)
      setBtnCat(props.filtre)
      return props.filtre
    }

  }, []);

  const showCatFunc = () => {
    if (showCat === true) {
      setShowCat(false)
    } else {
      setShowCat(true)
      setShowSec(false)
    }
  }
  const showSecFunc = () => {
    if (showSec === true) {
      setShowSec(false)
    } else {
      setShowCat(false)
      setShowSec(true)
    }
  }

  const filtreFunc = (condition) => {
    if (condition === "Delete") {
      setCondition("All")
      setBtnCat("Catégories")
      setShowCat(false)
      return
    }
    if (condition !== condition) {
      setCondition(condition)
      setBtnCat(condition)
      setShowCat(false)
    } else {
      setCondition('All')
      setBtnCat("Catégories")
      setShowCat(false)
    }
  }

  const filtreSectorFunc = (condition) => {
    if (condition === "Delete") {
      setConditionSector('All')
      setBtnSec("Secteur")
      setShowSec(false)
      return
    }
    if (condition !== conditionSector) {
      setConditionSector(condition)
      setBtnSec(condition)
      setShowSec(false)
    } else {
      setConditionSector('All')
      setBtnSec("Secteur")
      setShowSec(false)
    }
  }

  const changeCat = (categorie) => {
    setCondition(categorie)
    setBtnCat(categorie)
    setShowCat(false)
  }

  const filtreDelete = () => {
    setCondition("All")
    setConditionSector("All")
  }

  const navigatePage = (slug, cond) => {
    if (cond === "pas de navigate") {
      setIsBtn("true")
      return filtreFunc(slug)
    }

    if (cond === "nav") {
      return navigate(slug)
    }
  }


  return (
    <div style={{ minHeight: "100vh" }}>

      <div className={portfolioStyles.bg}></div>
      <div className={portfolioStyles.main}>
        <div className={portfolioStyles.rightPart}>
          {/*Bouton filtres categorie */}
          <div className={portfolioStyles.btnContainerFiltre}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button style={{ backgroundColor: btnCat === "Catégories" ? null : "#7600FF" }} className={[portfolioStyles.btnFiltre, portfolioStyles.btnFiltrePrincipal,].join(" ")} onClick={() => { showCatFunc() }}>
                <span>{btnCat}</span>

                {btnCat !== "Catégories" && (
                  <div onClick={e => {
                    filtreFunc("Delete")
                    e.stopPropagation()
                  }}
                    className={portfolioStyles.btnCancel}>X</div>
                )}
              </button>

              {/*Bouton filtres secteur */}
              <div>
                <button style={{ backgroundColor: btnSec === "Secteur" ? null : "#7600FF" }} className={[portfolioStyles.btnFiltre, portfolioStyles.btnFiltrePrincipal,].join(" ")} onClick={() => { showSecFunc() }}>
                  <span>{btnSec}</span>
                  {btnSec !== "Secteur" && (
                    <div onClick={e => {
                      filtreSectorFunc("Delete")
                      e.stopPropagation()
                    }}
                      className={portfolioStyles.btnCancel}>X</div>
                  )}
                </button>
              </div>
            </div>

            <div>
              <div className={portfolioStyles.filtres}>
                {showCat === true && (
                  <div className={portfolioStyles.categories}>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreFunc("Identité visuelle") }}>
                      {intl.formatMessage({ id: "identitéVisuelle" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreFunc("Site web") }}>
                      {intl.formatMessage({ id: "siteWeb" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreFunc("Application mobile") }}>
                      {intl.formatMessage({ id: "applicationMobile" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreFunc("Illustration") }}>
                      {intl.formatMessage({ id: "illustration" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreFunc("Animation") }}>
                      {intl.formatMessage({ id: "animation" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreFunc("3D") }}>
                      {intl.formatMessage({ id: "3D" })}
                    </button>
                  </div>
                )}
              </div>

              <div className={portfolioStyles.filtres}>
                {showSec === true && (
                  <div className={portfolioStyles.categories}>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Environnement") }}>
                      {intl.formatMessage({ id: "environnement" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Santé") }}>
                      {intl.formatMessage({ id: "santé" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Culture") }}>
                      {intl.formatMessage({ id: "culture" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Immobilier") }}>
                      {intl.formatMessage({ id: "immobilier" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Juridique") }}>
                      {intl.formatMessage({ id: "juridique" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Evenementiel") }}>
                      {intl.formatMessage({ id: "evenementiel" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Restauration") }}>
                      {intl.formatMessage({ id: "restauration" })}
                    </button>
                    <button className={portfolioStyles.btnFiltre}
                      onClick={() => { filtreSectorFunc("Technologie") }}>
                      {intl.formatMessage({ id: "technologie" })}
                    </button>

                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <ol className={portfolioStyles.posts}>
          {/* Grande Boucle de tous les projets */}

          {props.data.allContentfulProjects.edges.map(edge => {
            if (edge.node.node_locale === langue) {
              //Filtre par Secteur
              if (conditionSector === edge.node.sector || conditionSector === "All") {
                {
                  //Aucun Filtre sélectionné ==> On retourne la liste entière
                  if (condition === "All") {
                    var imageData = undefined
                    imageData = edge.node.mainPicture
                    if (imageData === null) {
                    } else {
                      imageData = edge.node.mainPicture.file.url
                    }
                    if (edge.node.category === null) {
                      edge.node.category = ["Pas de catégorie !"]
                    }
                    const image = getImage(edge.node.mainPicture)
                    const bgImage = convertToBgImage(image)

                    return (
                      <li key={edge.node.slug} className={portfolioStyles.post}>
                        {imageData && (
                          <BackgroundImage Tag="section" {...bgImage} backgroundColor={`#040e18`} className={portfolioStyles.backPost} width={"100%"} backgroundRepeat={"repeat-y"} backgroundSize={`cover`} backgroundPosition={`center`}>
                            <div className={portfolioStyles.postLink}
                              onClick={() => { navigatePage(`/${edge.node.slug}`, "nav") }}
                              to={`/${edge.node.slug}`} location={props.location} langue={langue}>
                              <div>
                                <div className={portfolioStyles.contentContainer}>
                                  <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h2 className={portfolioStyles.projectTitle}
                                      style={{
                                        display: "flex", justifyContent: "center",
                                        flexDirection: "column"
                                      }}>
                                      {edge.node.title}
                                    </h2>

                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <div className={[portfolioStyles.catContainer,].join(" ")}>

                                        {edge.node.category.map(categorie => {
                                          return (
                                            <div key={categorie}
                                              onClick={e => {
                                                navigatePage(categorie, "pas de navigate")
                                                e.stopPropagation()
                                              }}>
                                              <p className={portfolioStyles.catItem}>{categorie}</p>
                                            </div>
                                          )
                                        })}

                                        <div onClick={e => {
                                          filtreSectorFunc(edge.node.sector)
                                          e.stopPropagation()
                                        }}>
                                          <p className={portfolioStyles.catItem}>{edge.node.sector}</p>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                              <GatsbyImage
                                image={edge.node.mainPicture.gatsbyImageData}
                                src={`${edge.node.mainPicture.gatsbyImageData}`}
                                //data.contentfulProjects.mainPicture.file.url
                                alt={edge.node.mainPicture.title}
                                className={portfolioStyles.sectionAppercu}
                              />
                            </div>
                          </BackgroundImage>
                        )}
                      </li>
                    )
                  } else {
                    // Filtre catégorie sélectionné
                    var imageData = undefined
                    imageData = edge.node.mainPicture
                    if (imageData === null) {
                    } else {
                      imageData = edge.node.mainPicture.file.url
                    }
                    if (edge.node.category === null) {
                      edge.node.category = ["Pas de catégorie !"]
                    }
                    const image = getImage(edge.node.mainPicture)
                    const bgImage = convertToBgImage(image)

                    return (
                      <div key={edge.node.slug}>
                        <div className="projetContainer">
                          {edge.node.category.map(categorie => {
                            if (categorie === condition) {
                              //La catégorie sélectionnée correspond au projet
                              return (
                                <li key={edge.node.slug} className={portfolioStyles.post}>
                                  {imageData && (
                                    <BackgroundImage Tag="section" {...bgImage} backgroundColor={`#040e18`} className={portfolioStyles.backPost} width={"100%"} backgroundRepeat={"repeat-y"} backgroundSize={`cover`} backgroundPosition={`center`}>
                                      <div className={portfolioStyles.postLink}
                                        onClick={() => { navigatePage(`/${edge.node.slug}`, "nav") }} to={`/${edge.node.slug}`} location={props.location} langue={langue}>
                                        <div>
                                          <div className={portfolioStyles.contentContainer}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                              <h2 className={portfolioStyles.projectTitle} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>{edge.node.title}</h2>
                                              <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div className={[portfolioStyles.catContainer].join(" ")}>
                                                  {edge.node.category.map(categorie => {
                                                    return (
                                                      <div key={categorie} onClick={e => {
                                                        navigatePage(categorie, "pas de navigate")
                                                        e.stopPropagation()
                                                      }}>
                                                        <p className={portfolioStyles.catItem}>{categorie}</p>
                                                      </div>
                                                    )
                                                  })}

                                                  <div onClick={e => {
                                                    filtreSectorFunc(edge.node.sector)
                                                    e.stopPropagation()
                                                  }}>
                                                    <p className={portfolioStyles.catItem}>{edge.node.sector}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <GatsbyImage
                                          image={edge.node.mainPicture.gatsbyImageData}
                                          //data.contentfulProjects.mainPicture.file.url
                                          alt={edge.node.mainPicture.title}
                                          className={portfolioStyles.sectionAppercu}
                                        />
                                      </div>
                                    </BackgroundImage>
                                  )}
                                </li>
                              )
                            } else {
                            }
                            return null
                          })}
                        </div>
                      </div>
                    )
                  }
                }
              }
            }
            return null
          })}
        </ol>
      </div>

      <ScrollToTopBtn clicked={"#start"} />
      <FooterInfos />
    </div>
  )
}

